
	import { Component, Vue, Watch } from "vue-property-decorator";
	import { DatePicker, Input, Option, Select, Table, TableColumn, Modal, Button } from "view-design";
	import request from "@/request";
	import Loading from "@/components/loading/Loading.vue";
	import { Route } from "vue-router";
	import { ECharts, EChartsOption, init } from "echarts";

	// const step0 = require("@/assets/dealing.png");

	@Component({
		components: {
			DatePicker,
			Select,
			Option,
			Loading,
			Table,
			Input, Modal, Button
		},
		filters: {
			toPercent (change:number, total:number):string {
				if (total === 0) {
					return "- 0";
				} else {
					const percentage = change / (total - change) * 100;
					if (percentage > 0) {
						return "↑ " + percentage.toFixed(1) + "%";
					} else {
						return "↓ " + (-percentage.toFixed(1)) + "%";
					}
				}
			},
			toBackgroundColor (change:string):string {
				if (/↑/.test(change)) {
					return "background-color:#FF5C5C";
				} else {
					return "background-color:dodgerblue";
				}
			}
		}
	})
		export default class EnergyWater extends Vue {
            colors = ["#4261ED", "#EF9F24", "#c4ccD6", "#FD4848"];
            showContent = false;
            isDrawBack = false;
		    dateRange:Date[] = [ new Date(new Date().setDate(1)), new Date(Date.now() - 24 * 3600 * 1000) ];
		// 定义数据
		// 列表格式定义
		readonly columns:TableColumn[] = [
			// {
			// 	title: "序号",
			// 	align: "center",
			// 	type: "index",
			// 	width: 45
			// },
			{
				title: "状态",
				key: "status",
				align: "center",
                slot: "status",
				width: 60
			},
			// {
			// 	title: "优先级",
			// 	key: "priority",
			// 	align: "center",
			// 	width: 60
			// },
			{
				title: "问题描述",
				key: "describe",
				align: "center",
				width: 140
			},
			{
				title: "报修楼宇",
				key: "build",
				align: "center"
			},
			{
				title: "保修设备",
				key: "device",
				align: "center"
			},
			{
				title: "创建人",
				key: "createror",
				align: "center"
			},
			{
				title: "创建时间",
				key: "createTime",
				align: "center",
				width: 85
			},
			{
				title: "处理人",
				key: "handle",
				align: "center",
				width: 78
			},
			{
				title: "操作",
				key: "status",
				align: "center",
				width: 60,
				render: (h, params) => {
                    let that = this;
					return h("img", {
						style: {// 设置样式
							"width": "25px",
							"height": "25px"
						},
						attrs: {// 设置属性
							src: require("@/assets/detail.png")
						},
                        on: {
                            "click" () {
                                that.$router.push({
                                    path: "/repair/detail/579/"
                                });
                            }
                        }
					});
				}
			}
		];
		// 列表数据
		data:any[] = [
			{
				status: "已完成",
				priority: "低",
				describe: "第一排水龙头严重漏水",
				build: "东楼1F卫生间",
				device: "水表101",
				createror: "保洁：张桂香",
				createTime: "23.07.25",
				handle: "维修-李祥"
			},
			{
				status: "已完成",
				priority: "低",
				describe: "第一排水龙头严重漏水",
				build: "东楼1F卫生间",
				device: "水表101",
				createror: "保洁：张桂香",
				createTime: "23.07.25",
				handle: "维修-李祥"
			},
			{
				status: "已完成",
				priority: "低",
				describe: "第一排水龙头严重漏水",
				build: "东楼1F卫生间",
				device: "水表101",
				createror: "保洁：张桂香",
				createTime: "23.07.25",
				handle: "维修-李祥"
			},
			{
				status: "已完成",
				priority: "低",
				describe: "第一排水龙头严重漏水",
				build: "东楼1F卫生间",
				device: "水表101",
				createror: "保洁：张桂香",
				createTime: "23.07.25",
				handle: "维修-李祥"
			},
			{
				status: "已完成",
				priority: "低",
				describe: "第一排水龙头严重漏水",
				build: "东楼1F卫生间",
				device: "水表101",
				createror: "保洁：张桂香",
				createTime: "23.07.25",
				handle: "维修-李祥"
			},
            {
				status: "已完成",
				priority: "低",
				describe: "第一排水龙头严重漏水",
				build: "东楼1F卫生间",
				device: "水表101",
				createror: "保洁：张桂香",
				createTime: "23.07.25",
				handle: "维修-李祥"
			},
			{
				status: "已完成",
				priority: "低",
				describe: "第一排水龙头严重漏水",
				build: "东楼1F卫生间",
				device: "水表101",
				createror: "保洁：张桂香",
				createTime: "23.07.25",
				handle: "维修-李祥"
			}
		];
		// 表格加载中
		tableLoading:boolean = false;
        initModal () {
            this.$nextTick(() => {
                this.setChart3("modal_chart_shui");
            });
        }
        setChart3 (domName) {
			const myChart: HTMLElement = document.getElementById(domName) as HTMLElement;
			// 获取dom，断言HTMLElement类型，否则会报错
			const chart = init(myChart);
			let myOption:any = {
                title: {
                    text: "历史用水",
                    textStyle: {
                        color: "#515a6e",
                        fontWeight: 600,
                        fontSize: 14
                    }
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        label: {
                            backgroundColor: "#6a7985"
                        }
                    }
                },
				grid: {
					left: "5%",
					right: "4%",
					bottom: "3%",
					containLabel: true
				},
				xAxis: {
					type: "category",
					boundaryGap: false,
                    axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: "dashed",
                            color: ["#e0e2e3"]
						}
					},
					axisTick: {
						lineStyle: {
							width: 0
						}
					},
					axisLabel: {
						color: "#788CA1"
					},
					data: ["7:12", "7:13", "7:14", "7:15", "7:16", "7:17", "7:18"]
				},
				yAxis: {
					type: "value",
                    name: "T",
                    nameTextStyle: {
						fontSize: 10,
						align: "center",
						color: "#788CA1"
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: "dashed",
                            color: ["#e0e2e3"]
						}
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
                    axisLabel: {
						color: "#788CA1"
					}
				},
				series: [
					{
						// name: "空调系统",
						type: "line",
                        smooth: true,
					    symbol: "none",
						data: [120, 132, 101, 134, 90, 230, 210],
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.colors[0] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        }
					}
				]
			};
			chart.setOption(myOption);
		}

		// 点击列表
		onSelect (value:any) {
			console.log(value);
		}
        setChart4 (domName) {
			const myChart: HTMLElement = document.getElementById(domName) as HTMLElement;
			// 获取dom，断言HTMLElement类型，否则会报错
			const chart = init(myChart);
			let myOption:any = {
                legend: {
                    data: ["生活用水", "饮用水"],
                    itemWidth: 13,
                    itemHeight: 13,
                    textStyle: {
                        color: "#788CA1"
                    },
                    top: 0
                },
                tooltip: {
                    trigger: "axis"
                },
				grid: {
                    top: "10%",
					left: "5%",
					right: "4%",
					bottom: "3%",
					containLabel: true
				},
				xAxis: {
					type: "category",
					boundaryGap: false,
                    axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: "dashed",
                            color: ["#e0e2e3"]
						}
					},
					axisTick: {
						lineStyle: {
							width: 0
						}
					},
					axisLabel: {
						color: "#788CA1"
					},
					data: ["7:12", "7:13", "7:14", "7:15", "7:16", "7:17", "7:18"]
				},
				yAxis: {
                    name: "T",
					type: "value",
                    nameTextStyle: {
						fontSize: 10,
						align: "center",
						color: "#788CA1"
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: "dashed",
                            color: ["#e0e2e3"]
						}
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
                    axisLabel: {
						color: "#788CA1"
					}
				},
				series: [
					{
						name: "生活用水",
						type: "line",
                        smooth: true,
					    symbol: "none",
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.colors[0] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        },
						data: [100, 120, 150, 160, 80, 200, 200]
					},
                    {
						name: "饮用水",
						type: "line",
                        smooth: true,
					    symbol: "none",
						data: [120, 132, 101, 134, 90, 230, 210],
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.colors[1] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        }
					}
				],
                color: this.colors
			};
			chart.setOption(myOption);
		}
        setChart2 (domName) {
			const myChart: HTMLElement = document.getElementById(domName) as HTMLElement;
			// 获取dom，断言HTMLElement类型，否则会报错
			const chart = init(myChart);
			let myOption:any = {
                legend: {
                    data: ["生活用水", "饮用水"],
                    itemWidth: 13,
                    itemHeight: 13,
                    textStyle: {
                        color: "#788CA1"
                    },
                    top: 0
                },
                grid: {
                    top: "10%",
					left: "5%",
					right: "2%",
					bottom: "4%",
					containLabel: true
				},
                xAxis: {
                    type: "category",
                    data: ["1F", "2F", "3F", "4F", "5F", "6F", "7F"],
                    axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
					splitLine: {
						show: false
					},
					axisTick: {
						lineStyle: {
							width: 0
						}
					},
					axisLabel: {
						color: "#788CA1"
					}
                },
                yAxis: {
                    name: "T",
                    nameTextStyle: {
						fontSize: 10,
						align: "center",
						color: "#788CA1"
					},
                    type: "value",
                    splitLine: {
                        lineStyle: {
                            type: "dashed",
                            color: ["#e0e2e3"]
                        }
					},
                    axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
                    axisLabel: {
						color: "#788CA1"
					}
                },
                series: [
                    {
                        name: "生活用水",
                        data: [120, 180, 180, 80, 70, 110, 130],
                        type: "bar",
                        barGap: 0
                    },
                    {
                        name: "饮用水",
                        data: [100, 150, 160, 70, 60, 50, 100],
                        barGap: 0,
                        type: "bar"
                    }
                ],
                color: this.colors
            };
			chart.setOption(myOption);
		}
		mounted ():void {
			const data:any[] = [];
			// 单日用水分析echarts图
			this.setChart4("echart");
			// 各路用水量echarts柱状图
            this.setChart2("echart2");
		}
		@Watch("showContent")
		onShowContentChange () {
            this.$nextTick(() => {
			    this.initModal();
            });
		}
        @Watch("$store.state.shebeiModal")
        changeshebeiModal () {
            this.showContent = true;
        }
        @Watch("isDrawBack")
        onisDrawBackchange (val) {
            this.$store.commit("changeDraw", val);
        }
		beforeRouteLeave (from:Route, to:Route, next:Function):void {
			next();
		}
	}

